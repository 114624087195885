import React from "react";
import {Link, List, ListItem, Stack, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export function TableAide() {
    const {t} = useTranslation();
    return (
        <Stack mb={8} p={2}>
            <Stack alignItems="center" mb={8}>
                <Text textTransform="uppercase" fontSize="24px" color="energieCyan">
                    {t("aide:title")}
                </Text>
                <Text textTransform="uppercase" fontSize="18px" color="energieCyan">
                    {t("aide:subtitle")}
                </Text>
            </Stack>
            <List bg="energieCyan" color="white" p={2}>
                <ListItem>
                    <Text> {t("aide:text1")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text2")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text3")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text4")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text5")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text6")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text7")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text8")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text9")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text10")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text11")}</Text>
                </ListItem>
                <ListItem>
                    <Text> {t("aide:text12")}</Text>
                </ListItem>
                <ListItem>
                    {/* mail to */}
                    <Link href={`mailto:${t('aide:mail')}`}>{t("aide:text13")}</Link>
                </ListItem>
            </List>
        </Stack>
    );
}
