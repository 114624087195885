import React, {useEffect} from "react";
import {Box, useBreakpointValue} from "@chakra-ui/react";
import {SlideIn} from "components/SlideIn";
import {Header} from "components/Layout/Header";
import {Footer} from "components/Layout/Footer";
import {TableGlossaire} from "./_partials/TableGlossaire";
import {useApp} from "../../context/App/useApp";

export function PageGlossaire() {
    const isMobile = useBreakpointValue({base: true, md: false});
    const {setPageColor, setBackgroundColorNotif, setColorNotif, setColorNotifHover} =
        useApp();

    useEffect(() => {
        setPageColor("#36A9E1");
        setBackgroundColorNotif("#36A9E1");
        setColorNotif("#FFFFFF");
        setColorNotifHover("#000000");
    }, [setPageColor, setBackgroundColorNotif, setColorNotifHover, setColorNotif]);

    return (
        <SlideIn>
            <Header/>
            <Box p={4} w={isMobile ? "100%" : "70%"} mx="auto">
                <TableGlossaire/>
            </Box>
            <Footer/>
        </SlideIn>
    );
}
