import React from "react";
import PropTypes from "prop-types";
import {HStack, Stack, Text} from "@chakra-ui/react";

export function ItemGlossaire({question, reponse, icon}) {
    return (
        <Stack borderBottom="dotted 1px #36A9E1">
            <HStack color="energieCyan">
                {icon}
                <Text>{question}</Text>
            </HStack>
            <Text color="white">{reponse}</Text>
        </Stack>
    );
}

ItemGlossaire.propTypes = {
    question: PropTypes.string,
    reponse: PropTypes.string,
    icon: PropTypes.instanceOf(Object),
};
