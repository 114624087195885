import React, {useMemo, useState} from "react";
import {Formiz, useForm} from "@formiz/core";
import {Center, HStack, Spinner, Stack, StackDivider, Text, useBreakpointValue, useDisclosure,} from "@chakra-ui/react";
import {SlideIn} from "components/SlideIn";
import {Header} from "components/Layout/Header";
import {Footer} from "components/Layout/Footer";
import {useTranslation} from "react-i18next";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import IconLoupeDashboard from "components/Icons/icons-generated/IconLoupeDashboard";
import IconMain from "components/Icons/icons-generated/IconMain";
import IconDoubleMains from "components/Icons/icons-generated/IconDoubleMains";
import {ButtonWithIco} from "components/Buttons/ButtonWithIco";
import {useNavigate} from "react-router";
import {useAuthentificationContext} from "context/auth-context";
import QRCode from "react-qr-code";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {PDFDocument, rgb} from 'pdf-lib';
import {Document, Page, pdfjs} from 'react-pdf';
import {ModalCustom} from "../../components/Modal/Modal";
import {FieldFactory} from "../../components/fields/FieldFactory";
import {useGetPaysCurrentUser} from "../register/services/useGetPaysCurrentUser";
import {useUpdateUserNomPrenom} from "../utilisateur/services/useUpdateUserNomPrenom";
import {FormatDate} from "../../functions/common-scope/utils";
import {useLanguageContext} from "../../context/lang-context";
import './css/pdf.css'
import IconEnergie from "../../components/Icons/icons-generated/IconEnergie";

// eslint-disable-next-line react/prop-types
function PdfViewer({url}) {


    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({numPages: num}) => {
        setNumPages(num);
    }

    const options = useMemo(() => ({
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    }), []);
    const documentWidth = useBreakpointValue({base: "300px", md: "500px", lg: "800px"});

    return (
        <div id="ResumeContainer">
            <Document
                className="PDFDocument"
                style={{width: documentWidth}}
                options={options}
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page className="PDFPage" key={`page_${index + 1}`} pageNumber={index + 1}/>
                ))}
            </Document>
        </div>
    );
}

export function PageDashboard() {
    const {user, isLoading} = useAuthentificationContext();
    const {pays} = useGetPaysCurrentUser();

    const userName = user?.identite;
    const ville = user?.ville;
    const nom = user?.nom?.toUpperCase();
    const prenom = user?.prenom?.toUpperCase();
    const [pdfUrl, setPdfUrl] = useState(null);
    const {isOpen, onClose, onOpen} = useDisclosure();
    const {lang} = useLanguageContext();

    const pageColor = "#36A9E1";
    const {t} = useTranslation();
    const isMobile = useBreakpointValue({base: true, md: false});
    const navigate = useNavigate();
    const form = useForm();

    const {mutate: updateUser, isLoading: isLoadingUpdate} = useUpdateUserNomPrenom();
    const handleUpdate = (values) => {
        updateUser(values);
    };

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const modifyPdf = async () => {

        const file = `/pdf/${lang}/edited.pdf`
        // Charger le PDF avec pdf-lib

        try {
            const existingPdfBytes = await fetch(file).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            // Récupérer la première page du PDF
            const pages = pdfDoc.getPages();

            const firstPage = pages[0];
            const thirtheenPage = pages[11];

            const date = FormatDate(
                new Date(),
                "YYYY-MM-DD H:m:s",
                lang
            );
            const {height} = firstPage.getSize();

            // Ajouter du texte à la page
            firstPage.drawText(`${userName}`, {
                x: 290,
                y: height - 78,
                size: 24,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(ville, {
                x: 280,
                y: height - 105,
                size: 24,
                color: rgb(0, 0, 0),
            });

            // Ajouter la date actuelle

            firstPage.drawText(date, {
                x: 220,
                y: height - 125,
                size: 20,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(`${userName}`, {
                x: 270,
                y: height - 182,
                size: 14,
                color: rgb(0, 0, 0),
            });

            thirtheenPage.drawText(`${userName}`, {
                x: 440,
                y: height - 370,
                size: 14,
                color: rgb(0, 0, 0),
            });

            // Sauvegarder le PDF modifié
            const pdfBytes = await pdfDoc.save();

            // Créer un URL pour le PDF modifié
            const pdfBlob = new Blob([pdfBytes], {type: 'application/pdf'});
            const url = URL.createObjectURL(pdfBlob);

            const link = document.createElement('a');

            return {url, link};

        } catch (e) {
            console.log(e);
        }

        return {url: null, link: null};
    }

    // const afficherDeclaration = async () => {
    //     const {url} = await modifyPdf();
    //     if (url) {
    //         setPdfUrl(url);
    //     }
    // }

    const downloadDeclaration = async () => {

        const {url, link} = await modifyPdf();
        if (link) {
            link.href = url;
            link.download = 'Ma déclaration.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    }

    return (
        <SlideIn>
            <Header/>
            <Stack mx="auto" p={isMobile ? 4 : 12} minH="500px">
                <Stack
                    spacing={12}
                    p={4}
                    alignItems="center"
                    w="100%"
                    divider={<StackDivider border="1px solid rgba(255, 255, 255, 0.5);"/>}
                >
                    <HStack
                        cursor="pointer"
                        alignItems="center"
                        onClick={() => {
                            navigate(`/dashboard/historique`);
                        }}
                    >
                        <IconEnergieSansPlus h="64px" w="64px" color={pageColor}/>
                        <Stack spacing={0}>
                            <Text color={pageColor} fontSize="48px" lineHeight="42px;">
                                {user?.energiesDisponible}
                            </Text>
                            <Text fontStyle="ExtraLight" fontSize="21px" color="#B0B0B0">
                                {t("dashboard:capital")}
                            </Text>
                        </Stack>
                    </HStack>
                    {isLoading ? (
                        <Center>
                            <Spinner/>
                        </Center>
                    ) : (
                        <Stack
                            direction={isMobile ? "column" : "row"}
                            w="100%"
                            spacing={12}
                            alignItems={isMobile ? "center" : ""}
                        >
                            <ButtonWithIco
                                onClick={() => {
                                    navigate(`/account/proposerDon`, {
                                        replace: true,
                                    });
                                }}
                                textTransform="uppercase"
                                color={pageColor}
                                text={t("app:menu.proposerUnDon")}
                                icon={<IconDoubleMains color={pageColor}/>}
                            />
                            <ButtonWithIco
                                textTransform="uppercase"
                                onClick={() => navigate("/dashboard/dons")}
                                color={pageColor}
                                text={t("app:menu.rechercherUnDon")}
                                icon={<IconLoupeDashboard color={pageColor}/>}
                            />
                            <ButtonWithIco
                                textTransform="uppercase"
                                onClick={() => navigate("/dashboard/favoris")}
                                color={pageColor}
                                text={t("app:menu.favoris")}
                                icon={<IconMain color={pageColor}/>}

                            />
                            {lang === "fr" ? <ButtonWithIco
                                textTransform="uppercase"
                                onClick={() => downloadDeclaration()}
                                color={pageColor}
                                text={t('account:declaration')}
                                icon={<IconEnergie color={pageColor}/>}
                            /> : null}

                        </Stack>
                    )}

                    <QRCode size={256} value="https://divinenergy.network" viewBox="0 0 256 256"/>

                    {user?.statut === 3 ? (
                        <ButtonWithIco
                            textTransform="uppercase"
                            onClick={() => navigate("/admin")}
                            color={pageColor}
                            text={t("app:menu.admin")}
                            icon={<IconLoupeDashboard color={pageColor}/>}
                        />
                    ) : null}
                </Stack>

                <Formiz id="pdf-form" onValidSubmit={handleUpdate} autoForm connect={form}>
                    <ModalCustom
                        title={t("account:qrCodePdf")}
                        bgColor="energieCyan"
                        color="white"
                        isOpen={isOpen}
                        onClose={onClose}
                        handleValider={form.submit}
                        isLoading={isLoadingUpdate}
                    >
                        <Stack justifyContent="center" spacing={4} p={4}>
                            <FieldFactory
                                toUpperCase
                                label={t("app:core.nom")}
                                type="text"
                                name="nom"
                                defaultValue={user?.nom}
                                required={`${t("app:core.form.required.identiteParticulier")}`}
                            />
                            <FieldFactory
                                toUpperCase
                                label={t("app:core.prenom")}
                                type="text"
                                name="prenom"
                                defaultValue={user?.prenom}
                                required={`${t("app:core.form.required.identiteParticulier")}`}
                            />
                        </Stack>

                    </ModalCustom>
                </Formiz>
            </Stack>
            <Footer/>
        </SlideIn>
    );
}
