import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {useApp} from "context/App/useApp";
import {ErrorPage} from "components/ErrorPage";
import {PageDashboard} from "App/dashboard/PageDashboard";
import {PageHistorique} from "App/historique/PageHistorique";
import {PageRechercheDons} from "App/dons/PageRechercheDons";
import {PageRechercheDonsFavoris} from "App/dons/PageRechercheDonsFavoris";
import {PageAide} from "App/aide/PageAide";
import {PageGlossaire} from "./App/glossaire/PageGlossaire";

export function DashboardRoutes() {
    const {setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover} = useApp();

    useEffect(() => {
        setPageColor("#36A9E1");
        setBackgroundColorNotif("#36A9E1");
        setColorNotif("#FFFFFF");
        setColorNotifHover("#000000");
    }, [setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover]);

    return (
        <Routes>
            <Route path="/" element={<PageDashboard/>}/>
            <Route path="/historique" element={<PageHistorique/>}/>
            <Route path="/dons" element={<PageRechercheDons/>}/>
            <Route path="/favoris" element={<PageRechercheDonsFavoris/>}/>
            <Route path="/aide" element={<PageAide/>}/>
            <Route path="/glossaire" element={<PageGlossaire/>}/>
            <Route path="*" element={<ErrorPage errorCode={404}/>}/>
        </Routes>
    );
}
