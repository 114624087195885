import React from "react";
import {Heading, Stack, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export function TextMentions() {
    const {t} = useTranslation();
    return (
        <Stack bg="energieCyan" textTransform="uppercase" p={2}>
            <Heading color="white">{t("mentions:editeur")}</Heading>
            <Text color="white">{t("mentions:editeurText")}</Text>
            <Heading color="white">{t("mentions:benediction")}</Heading>
            <Text color="white">{t("mentions:benedictionText")}</Text>
            <Heading color="white">{t("mentions:legitimites")}</Heading>
            <Text color="white">{t("mentions:legitimitesText")}</Text>
            <Heading color="white">{t("mentions:securites")}</Heading>
            <Text color="white">{t("mentions:securitesText")}</Text>
            <Text color="white">{t("mentions:securitesText2")}</Text>
            <Heading color="white">{t("mentions:notes")}</Heading>
            <Text color="white">{t("mentions:notesText")}</Text>
        </Stack>
    );
}
