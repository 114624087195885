import React from "react";
import {Stack, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import IconEnergie from "components/Icons/icons-generated/IconEnergie";
import IconNotif from "components/Icons/icons-generated/IconNotif";
import IconNotifActif from "components/Icons/icons-generated/IconNotifActif";
import IconCopier from "components/Icons/icons-generated/IconCopier";
import IconFavoris from "components/Icons/icons-generated/IconFavoris";
import {IconValider} from "components/Icons";
import IconDownload from "components/Icons/icons-generated/IconDowload";
import {ItemGlossaire} from "./ItemGlossaire";

export function TableGlossaire() {
    const {t} = useTranslation();
    const pageColor = "#36A9E1";
    return (
        <Stack mb={8} textTransform="uppercase">
            <Stack alignItems="center" mb={8}>
                <Text textTransform="uppercase" fontSize="24px" color="energieCyan">
                    {t("glossaire:title")}
                </Text>
            </Stack>
            <ItemGlossaire question={t("glossaire:question.elu")} reponse={t("glossaire:reponse.elu")}/>
            <ItemGlossaire question={t("glossaire:question.tribu")} reponse={t("glossaire:reponse.tribu")}/>
            <ItemGlossaire question={t("glossaire:question.vertus")} reponse={t("glossaire:reponse.vertus")}/>
            <ItemGlossaire question={t("glossaire:question.jeSuis")} reponse={t("glossaire:reponse.jeSuis")}/>
            <ItemGlossaire question={t("glossaire:question.initiation")} reponse={t("glossaire:reponse.initiation")}/>
            <ItemGlossaire question={t("glossaire:question.usurpateur")} reponse={t("glossaire:reponse.usurpateur")}/>
            <ItemGlossaire question={t("glossaire:question.cercleDesSages")} reponse={t("glossaire:reponse.cercleDesSages")}/>

            <ItemGlossaire question={t("glossaire:question.don")} reponse={t("glossaire:reponse.don")}/>
            <ItemGlossaire question={t("glossaire:question.donPonctuel")} reponse={t("glossaire:reponse.donPonctuel")}/>
            <ItemGlossaire
                question={t("glossaire:question.donPermanent")}
                reponse={t("glossaire:reponse.donPermanent")}
            />
            <ItemGlossaire question={t("glossaire:question.donMensuel")} reponse={t("glossaire:reponse.donMensuel")}/>
            <ItemGlossaire question={t("glossaire:question.donFavori")} reponse={t("glossaire:reponse.donFavori")}/>
            <ItemGlossaire question={t("glossaire:question.projetDeVie")} reponse={t("glossaire:reponse.projetDeVie")}/>

            <ItemGlossaire question={t("glossaire:question.collectif")} reponse={t("glossaire:reponse.collectif")}/>
            <ItemGlossaire question={t("glossaire:question.reference")} reponse={t("glossaire:reponse.reference")}/>
            <ItemGlossaire question={t("glossaire:question.clePublique")} reponse={t("glossaire:reponse.clePublique")}/>
            <ItemGlossaire
                question={t("glossaire:question.statutDonNotifie")}
                reponse={t("glossaire:reponse.statutDonNotifie")}
            />
            <ItemGlossaire
                question={t("glossaire:question.statutDonValide")}
                reponse={t("glossaire:reponse.statutDonValide")}
            />
            <ItemGlossaire question={t("glossaire:question.historique")} reponse={t("glossaire:reponse.historique")}/>
            <ItemGlossaire
                question={t("glossaire:question.divinEnergy")}
                icon={<IconEnergie w="30px" color={pageColor} fill={pageColor}/>}
                reponse={t("glossaire:reponse.divinEnergy")}
            />
            <ItemGlossaire
                question={t("glossaire:question.notifications")}
                icon={
                    <Stack h="60px">
                        <IconNotif w="30px" color={pageColor}/>
                        <IconNotifActif w="30px" color={pageColor}/>
                    </Stack>
                }
                reponse={t("glossaire:reponse.notifications")}
            />
            <ItemGlossaire
                question={t("glossaire:question.copierColler")}
                icon={<IconCopier w="30px" stroke={pageColor} fill={pageColor}/>}
                reponse={t("glossaire:reponse.copierColler")}
            />
            <ItemGlossaire
                question={t("glossaire:question.favoris")}
                icon={<IconFavoris w="30px" stroke={pageColor} fill={pageColor}/>}
                reponse={t("glossaire:reponse.favoris")}
            />
            <ItemGlossaire
                question={t("glossaire:question.validation")}
                icon={<IconValider w="30px" color="energieCyan" fill={pageColor}/>}
                reponse={t("glossaire:reponse.validation")}
            />
            <ItemGlossaire
                question={t("glossaire:question.reception")}
                icon={<IconDownload h="30px" w="30px"/>}
                reponse={t("glossaire:reponse.reception")}
            />
        </Stack>
    );
}
